import styled from '@emotion/styled';
import { DocumentType } from '@innedit/innedit-type';
import classnames from 'classnames';
import dayjs from 'dayjs';
import objectHash from 'object-hash';
import { rem } from 'polished';
import React, { FC, ReactNode, SyntheticEvent, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import Actions from '~/components/Actions';
import { ActionProps } from '~/components/Actions/props';
import Tag, { TagStateProps } from '~/components/Tag';
import IconComment from '~/icons/Comment';

import IconSort from '../../../icons/Sort';
import { borderRadius, colors, spacing } from '../../../styles/theme';
import Button, { ButtonEL } from '../../Button';

const ItemTypes = {
  ITEM: 'item',
};

interface DragProduit {
  index: number;
  type: string;
}

const ItemSC = styled.li`
  display: flex;
  position: relative;

  justify-content: space-between;
  // min-height: ${rem(54)};
  box-sizing: border-box;

  .block {
    display: block;
    font-size: 80%;
  }

  .actions {
    display: flex;
    align-items: center;
    padding: 0 ${spacing[1.5]};
  }

  .field-actions {
    margin: 0;
  }

  &:first-of-type {
    border-top-right-radius: ${borderRadius.s};
  }

  &:last-child {
    border-bottom-right-radius: ${borderRadius.s};
  }
`;

interface ListItemProps<T> {
  actions?: ActionProps | ActionProps[];
  after?: ReactNode;
  className?: string;
  changePosition?: (oldIndex: number, newIndex: number) => void;
  date?: string;
  description?: string;
  displayActionId?: boolean;
  doc: DocumentType<T>;
  icon?: ReactNode;
  index?: number;
  label: string;
  onClick?: (event: SyntheticEvent<HTMLButtonElement | HTMLDivElement>) => void;
  subLabel?: string;
  tags?: { label: string; state?: TagStateProps }[];
}

const ListItem: FC<ListItemProps<any>> = ({
  actions,
  after,
  changePosition,
  children,
  className,
  date,
  description,
  displayActionId = false,
  doc,
  icon,
  index,
  label,
  onClick,
  subLabel,
  tags,
}) => {
  const ref = useRef<HTMLLIElement>(null);

  const [{ handlerId, isOver }, drop] = useDrop({
    accept: ItemTypes.ITEM,
    collect: (monitor: any) => ({
      canDrop: monitor.canDrop(),
      handlerId: monitor.getHandlerId(),
      isOver: monitor.isOver(),
    }),
    drop(item: DragProduit) {
      if (!ref.current || undefined === index) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      if (changePosition) {
        changePosition(dragIndex, hoverIndex);
      }
    },
  });

  const [{ isDragging }, drag, previous] = useDrag({
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => ({ index, type: ItemTypes.ITEM }),
    type: ItemTypes.ITEM,
  });

  if (changePosition) {
    previous(drop(ref));
  }

  // 'flex border p-1.5 justify-between',
  return (
    <>
      <ItemSC
        ref={ref}
        className={classnames('items-center space-x-3', 'pr-0', className, {
          // '!bg-primary-500': isDragging,
          // '!bg-secondary-500': isOver,
          // 'bg-tertiary-500': canDrop,
          'bg-neutral-200': isOver,
          'ml-3': icon,
          'ml-6': !icon,
          'opacity-40': isDragging,
        })}
        data-handler-id={handlerId}
      >
        {icon && (
          <div className="my-2 flex h-[42px] w-[42px] items-center justify-center md:py-1.5">
            {icon}
          </div>
        )}
        <div
          className={classnames(
            'flex flex-1 items-center justify-between overflow-hidden py-2 md:py-1.5',
            {
              'border-b': true,
            },
          )}
        >
          <div className="flex flex-1 cursor-pointer items-center space-x-3 overflow-hidden">
            <div
              className="flex min-h-[42px] flex-1 flex-col justify-center overflow-hidden pr-3"
              data-id={doc.id}
              onClick={onClick}
              role="presentation"
            >
              <div className="flex justify-between">
                <div className="flex items-center space-x-3 overflow-hidden">
                  <h2 className="truncate text-[17px] font-[400] md:text-[15px]">
                    {label}
                  </h2>
                  {doc.nbComments !== undefined && doc.nbComments > 0 && (
                    <IconComment
                      className="hidden h-[14px] w-[14px] md:flex"
                      color={colors.neutral[300]}
                    />
                  )}
                </div>

                {date && (
                  <span className="ml-3 whitespace-nowrap text-[15px] text-light-800 md:text-[14px]">
                    {dayjs(date).format('D MMM')}
                  </span>
                )}
              </div>
              {subLabel && (
                <h3 className="truncate text-[15px] font-normal md:text-[14px]">
                  {subLabel}
                </h3>
              )}
              {description && (
                <p
                  className="max-h-[40px] overflow-hidden text-ellipsis text-[15px] leading-[20px] text-light-800 md:text-[14px]"
                  dangerouslySetInnerHTML={{
                    __html: description.replace(/\n/g, '<br/>'),
                  }}
                />
              )}
              {children && (
                <div
                  className={classnames(
                    'flex w-full flex-1 flex-col items-start justify-between md:flex-row md:items-center',
                  )}
                >
                  {children}
                </div>
              )}
              {tags && tags.length > 0 && (
                <div className="mt-2 flex items-start space-x-1">
                  {tags.map((tag, i) => (
                    <Tag key={objectHash({ i, tag })} state={tag.state}>
                      {tag.label}
                    </Tag>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="actions hidden space-x-1 pl-3 md:flex">
            {changePosition && (
              <ButtonEL ref={drag} variant="action">
                <IconSort />
              </ButtonEL>
            )}
            {actions && (
              <Actions items={Array.isArray(actions) ? actions : [actions]} />
            )}
            {doc.id && displayActionId && (
              <Button
                className="hidden md:flex"
                tooltip={doc.id}
                type="tooltip"
                variant="action"
              />
            )}
          </div>
        </div>
      </ItemSC>
      {after}
    </>
  );
};

export default ListItem;
